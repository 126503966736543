import { shouldBeUrl, isDomain } from '../../../../shared/helpers/Validators';

class ValidationError extends Error {
  constructor(message, field) {
    super(message);
    this.name = 'ValidationError';
    this.field = field;
  }
}

const channelValidators = {
  api: channelConfig => {
    if (channelConfig.webhook_url && !shouldBeUrl(channelConfig.webhook_url)) {
      throw new ValidationError('Invalid webhook url', 'webhook_url');
    }
  },
  web_widget: channelConfig => {
    if (channelConfig.website_url && !isDomain(channelConfig.website_url)) {
      throw new ValidationError('Invalid website_url', 'website_url');
    }
  },
  telegram: channelConfig => {
    if (channelConfig.botToken && channelConfig.botToken != '') {
      throw new ValidationError('Invalid botToken', 'botToken');
    }
  },
  line: channelConfig => { },
  whatsapp: channelConfig => { },
  facebook_page: channelConfig => { },
};

const humanEscalationValidator = (humanEscalation, humanEscalationConfig, humanEscalationOptions) => {
  if (humanEscalation == null) {
    return
  }

  if (!humanEscalationOptions.map(option => option.key).includes(humanEscalation)) {
    throw new ValidationError('Invalid human escalation option', 'human_escalation')
  }

  if (!humanEscalationConfig) {
    throw new ValidationError('Please make sure all field are filled in', 'human_escalation')
  }

  if (!humanEscalationConfig.item || humanEscalationConfig.item == '') {
    throw new ValidationError('Please make sure all field are filled in', 'human_escalation')
  }

  if (!humanEscalationConfig.value || humanEscalationConfig.value == '') {
    throw new ValidationError('Please make sure all field are filled in', 'human_escalation')
  }
}


export const validate = state => {
  const regex = /^(?!.*[<>\/\\@])(?![^\w\s])(?!.*[^\w\s]$)[^\s].*[^\s]$/m;
  if (!regex.test(state.name)) {
    throw new ValidationError('Name should not start or end with symbols, and it should not have < > / \ @ characters', 'name');
  }

  if (!state.tasks.map(l_task => l_task.key).includes(state.task)) {
    throw new ValidationError('Invalid task', 'task');
  }

  if (!state.models.map(l_model => l_model.key).includes(state.model)) {
    throw new ValidationError('Invalid model', 'model');
  }

  if (
    !state.languages.map(l_language => l_language.key).includes(state.language)
  ) {
    throw new ValidationError('Invalid language', 'language');
  }

  if (!state.channels.map(l_channel => l_channel.key).includes(state.channel)) {
    throw new ValidationError('Invalid channel', 'channel');
  }

  // Validate the channel configuration
  channelValidators[state.channel](state.channelConfig[state.channel] || {});
  humanEscalationValidator(state.humanEscalation, state.humanEscalationConfig, state.humanEscalationOptions)
};
